import "./AddForm.css";
import { BsCloudDownload } from "react-icons/bs";
export default function AddForm(props) {
  const { title, setTitle, saveTask } = props;
  return (
    <>
      <h2>แอพบริหารจัดการงาน</h2>
      <form onSubmit={saveTask} className="form-group">
        <div className="form-control">
          <input
            type="text"
            className="text-input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></input>
          <button type="submit" className="submit-btn">
            <BsCloudDownload />
          </button>
        </div>
      </form>
    </>
  );
}
