import "./App.css";
import Header from "./components/Header";
import AddForm from "./components/AddForm";
import Item from "./components/Item";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
function App() {
  const [tasks, setTasks] = useState(
    JSON.parse(localStorage.getItem("tasks")) || []
  );
  const [title, setTitle] = useState("");
  const [editId, setEditId] = useState(null);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    localStorage.setItem("tasks", JSON.stringify(tasks));
  }, [tasks]);

  function deleteTask(id) {
    const result = tasks.filter((item) => item.id !== id);
    setTasks(result);

    Swal.fire({
      title: "ลบข้อมูลเรียบร้อย!",
      icon: "success",
      timer: 1000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  }

  function editTask(id) {
    const editTask = tasks.find((item) => item.id === id);

    Swal.fire({
      title: "แก้ไขงาน",
      input: "text",
      inputValue: editTask.title, // ใส่ค่าเดิมของ task ลงในช่อง input
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      cancelButtonText: "ยกเลิก",
      inputValidator: (value) => {
        if (!value) {
          return "กรุณาป้อนข้อมูล!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedTasks = tasks.map((item) => {
          if (item.id === id) {
            return { ...item, title: result.value };
          }
          return item;
        });
        setTasks(updatedTasks);

        Swal.fire({
          title: "แก้ไขข้อมูลเรียบร้อย!",
          icon: "success",
          timer: 1000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    });
  }

  function saveTask(e) {
    e.preventDefault();
    if (!title) {
      Swal.fire({
        title: "กรุณาป้อนข้อมูล!",
        icon: "error",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else if (editId) {
      const updateTask = tasks.map((item) => {
        if (item.id === editId) {
          return { ...item, title: title };
        }
        return item;
      });
      setTasks(updateTask);
      Swal.fire({
        title: "แก้ไขข้อมูลเรียบร้อย!",
        icon: "success",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setEditId(null);
      setTitle("");
    } else {
      const newTask = {
        id: Math.floor(Math.random() * 1000),
        title: title,
      };
      setTasks([...tasks, newTask]);
      setTitle("");

      Swal.fire({
        title: "เพิ่มข้อมูลเรียบร้อย!",
        icon: "success",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  }

  return (
    <>
      <div className={"App " + theme}>
        <Header theme={theme} setTheme={setTheme} />

        <div className="container">
          <AddForm
            title={title}
            setTitle={setTitle}
            saveTask={saveTask}
            editId={editId}
          />
          <section className="item-container">
            {tasks.map((data) => (
              <Item
                key={data.id}
                data={data}
                deleteTask={deleteTask}
                editTask={editTask}
              />
            ))}
          </section>
        </div>
      </div>
    </>
  );
}

export default App;
