import "./Item.css";
import { BsTrash, BsWrench } from "react-icons/bs";
export default function Item(props) {
  const { data, deleteTask, editTask } = props;
  return (
    <div className="list-item">
      <p className="title">{data.title}</p>
      <div className="button-container">
        <BsTrash className="btn" onClick={() => deleteTask(data.id)}></BsTrash>
        <BsWrench className="btn" onClick={() => editTask(data.id)}>
          แก้ไข
        </BsWrench>
      </div>
    </div>
  );
}
